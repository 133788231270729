import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeThemeSkeleton } from "./TypeTheme";
import type { TypeTopicSkeleton } from "./TypeTopic";

export type TypeStaffFields = {
  name: EntryFieldTypes.Symbol;
  slug: EntryFieldTypes.Symbol;
  currentEmployee: EntryFieldTypes.Boolean;
  jobTitle?: EntryFieldTypes.Symbol;
  team: EntryFieldTypes.Symbol<"Executive" | "HR & Operations" | "Investor Outreach & Member Services" | "Marketing & Communications" | "Policy" | "Research & Engagements">;
  themeOrTopic?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeThemeSkeleton | TypeTopicSkeleton>>;
  email?: EntryFieldTypes.Symbol;
  linkedIn?: EntryFieldTypes.Symbol;
  shortBio: EntryFieldTypes.RichText;
  description: EntryFieldTypes.RichText;
  thumbnail?: EntryFieldTypes.AssetLink;
};

export type TypeStaffSkeleton = EntrySkeletonType<TypeStaffFields, "staff">;
export type TypeStaff<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeStaffSkeleton, Modifiers, Locales>;

export function isTypeStaff<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeStaff<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "staff";
}
